import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPencilRuler,
    faPenFancy,
    faFileCode,
} from "@fortawesome/free-solid-svg-icons"

import * as containerStyles from "./services.module.css"

export default function Services() {
    return (
        <div className={containerStyles.serviceSection}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.serviceTitle}>
                        <h2 id="services">03. MY SERVICES</h2>
                    </div>
                    <div className={containerStyles.serviceContent}>
                        <p>I’m currently transitioning from content creation (writing) to web development using state-of-the-art frameworks like GatsbyJS & eventually programming.</p>
                    </div>
                </div>
                <div className={containerStyles.row}>
                    <div className={containerStyles.serviceWrapper}>
                        <div className={containerStyles.serviceItem}>
                            <div className={containerStyles.singleService}>
                                <div className={containerStyles.innerBox}>
                                    <FontAwesomeIcon icon={faPenFancy} size="3x" alt="Pen" />
                                    <h3>CONTENT WRITING</h3>
                                    <p>I have over 10 years of experience both print and online in delivering high-quality content. From sports, motivational stories & fiction, to gambling, gaming & crypto… you name it!</p>
                                </div>
                            </div>
                        </div>
                        <div className={containerStyles.serviceItem}>
                            <div className={containerStyles.singleService}>
                                <div className={containerStyles.innerBox}>
                                    <FontAwesomeIcon icon={faPencilRuler} size="3x" alt="Pen & Ruler" />
                                    <h3>WEB DEVELOPMENT</h3>
                                    <p>I’ve been working with Wordpress for more than 8 years & now, I’m finally ready to take it to the next level by implementing state-of-the-art frontend frameworks like GatsbyJS.</p>
                                </div>
                            </div>
                        </div>
                        <div className={containerStyles.serviceItem}>
                            <div className={containerStyles.singleService}>
                                <div className={containerStyles.innerBox}>
                                    <FontAwesomeIcon icon={faFileCode} size="3x" alt="File Code" />
                                    <h3>PROGRAMMING</h3>
                                    <p>A work in progress, nonetheless, willing to take it one step at a time. Javascript was the starting point with plans to try out Unity & C-based languages, in the future. The endpoint? Still a blur… </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}