import React from "react"

import * as containerStyles from "./burger.module.css"

export default function Burger() {
    return (
        <div className={containerStyles.burgerBox}>
            <span className={containerStyles.burgerInner}></span>
        </div>
    )
}