import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faSpinner,
    faMobileAlt,
    faTag,
} from "@fortawesome/free-solid-svg-icons"

import * as containerStyles from "./overlay.module.css"

export default function Overlay() {
    return (
        <div>
            <div className={containerStyles.parallax}>
                <div className={containerStyles.container}>
                    <div className={containerStyles.row}>
                        <div className={containerStyles.leftItem}>
                            <FontAwesomeIcon icon={faSpinner} spin size="4x" alt="Spinner" className={containerStyles.awesomeIcon} />
                            <h3>State of the Art Tools</h3>
                        </div>
                        <div className={containerStyles.centerItem}>
                            <FontAwesomeIcon icon={faMobileAlt} alt="Mobile" size="4x" className={containerStyles.awesomeIcon} />
                            <h3>Top Notch Communication</h3>
                        </div>
                        <div className={containerStyles.rightItem}>
                            <FontAwesomeIcon icon={faTag} size="4x" alt="Tag" className={containerStyles.awesomeIcon} />
                            <h3>Great Value For Your Money's Worth</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}