// extracted by mini-css-extract-plugin
export const skillSection = "skills-module--skillSection--1EVST";
export const container = "skills-module--container--J11iu";
export const row = "skills-module--row--31vQu";
export const skillContent = "skills-module--skillContent--3ybQF";
export const skillTitle = "skills-module--skillTitle--3dvSQ";
export const skillsGraph = "skills-module--skillsGraph--3_aKH";
export const barItem = "skills-module--barItem--48e12";
export const progress = "skills-module--progress--3kV8u";
export const fill95 = "skills-module--fill95--Dq5gG";
export const fill70 = "skills-module--fill70--2Ltic";
export const fill40 = "skills-module--fill40--1Cre6";
export const fill20 = "skills-module--fill20--3NGlu";
export const skillImage = "skills-module--skillImage--2pkGc";
export const imageWrapper = "skills-module--imageWrapper--1NvZP";