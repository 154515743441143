import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { 
faTelegramPlane,
faSkype, 
} from "@fortawesome/free-brands-svg-icons"
import {
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

import * as containerStyles from "./contact.module.css"

export default function Contact() {
    return (
        <div className={containerStyles.contactSection}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.contactInfo}>
                        <div>
                            <div className={containerStyles.contactTitle}>
                                <h2>05. DROP ME A LINE</h2>
                            </div>
                            <div className={containerStyles.contactDesc}>
                                <p>For more details about my services or for any other enquiries related to a possible collaboration, please do not hesitate to use the contact form on the right. I usually respond in maximum 24 hours. Thank you for your patience. </p>
                            </div>
                            <div>
                                <div className={containerStyles.singleInfo}>
                                    <div className={containerStyles.infoDetails}>
                                        <FontAwesomeIcon icon={faEnvelope} size="2x" alt="Email" className={containerStyles.awesomeIcons} />
                                        <span>contact@florianghe.com</span>
                                    </div>
                                </div>
                                <div className={containerStyles.singleInfo}>
                                    <div className={containerStyles.infoDetails}>
                                        <FontAwesomeIcon icon={faSkype} alt="Skype" size="2x" className={containerStyles.awesomeIcons} />
                                        <span>Contact Me For Info</span>
                                    </div>
                                </div>
                                <div className={containerStyles.singleInfo}>
                                    <div className={containerStyles.infoDetails}>
                                        <FontAwesomeIcon icon={faTelegramPlane} alt="Telegram" size="2x" className={containerStyles.awesomeIcons} />
                                        <span>Contact Me For Info</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={containerStyles.contactForm} id="contact">
                        <div className={containerStyles.formWrapper}>
                            <form action="https://formspree.io/mwkavkqe" method="post" className={containerStyles.form}>
                                <div className={containerStyles.singleInput}>
                                    <input type="text" name="your_name" placeholder="Your Name*" required />
                                </div>
                                <div className={containerStyles.singleInput}>
                                    <input type="email" name="_replyto" placeholder="Your Email*" required />
                                </div>
                                <div className={containerStyles.singleInput}>
                                    <input type="text" name="subject" placeholder="Subject" />
                                </div>
                                <div className={containerStyles.contactMessage}>
                                    <textarea name="your_message" placeholder="Message*" required></textarea>
                                </div>
                                <div className={containerStyles.formBtn}>
                                    <button type="submit" aria-label="Send"><FontAwesomeIcon icon={faTelegramPlane} alt="Send" size="2x" aria-hidden="true" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}