import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faTwitter,
    faGithub,
    faLinkedin,

} from "@fortawesome/free-brands-svg-icons"
import {
    faEnvelope,
    faLongArrowAltDown,
} from "@fortawesome/free-solid-svg-icons"

import * as containerStyles from "../components/main-card.module.css"

export default function MainCard() {
    return (
    <div className={containerStyles.mainCard}>
        <div className={containerStyles.overlay}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.whiteBox}>
                        <div className={containerStyles.rightBox}>
                            <div className={containerStyles.mainImage}>
                                <StaticImage src="..\images\black-white-main-card.jpg" alt="Florian Gheorghe" />
                            </div>
                        </div>
                        <div className={containerStyles.leftBox}>
                            <div className={containerStyles.cardTop}>
                                <h1>I AM FLORIAN GHEORGHE</h1>
                                <p>DIGITAL PLAYER & CREATIVE SPIRIT / ANTI-ESTABLISHMENT EXTRAORDINAIRE & FREEDOM EVANGELIST</p>
                            </div>
                            <div className={containerStyles.cardInfo}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <th>FULL NAME:</th>
                                            <td>Florian Gheorghe</td>
                                        </tr>
                                        <tr>
                                            <th>BIRTHDAY/AGE:</th>
                                            <td>October 5th, 1985/35 years old</td>
                                        </tr>
                                        <tr>
                                            <th>AVAILABILITY:</th>
                                            <td><a href="#contact">Let's Talk First...</a></td>
                                        </tr>
                                        <tr>
                                            <th>QUOTE:</th>
                                            <td>To Be Determined</td>
                                        </tr>
                                        <tr>
                                            <th>HOBBIES:</th>
                                            <td>Travelling, Jogging & Reading (actual books, not memes)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={containerStyles.cardSocial}>
                                <ul>
                                    <li className={containerStyles.mobileBlock}><span>Find Me On Social Media:</span></li>
                                    <li className={containerStyles.tooltip}><a href="https://twitter.com/floriangoga" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faTwitter} size="1.5x" alt="Check My Twitter" /></a><p className={containerStyles.tooltipText}>Find Me On Twitter</p></li>
                                    <li className={containerStyles.tooltip}><a href="https://github.com/mogulyevich" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faGithub} size="1.5x" alt="Check My Github" /></a><p className={containerStyles.tooltipText}>Find Me On Github</p></li>
                                    <li className={containerStyles.tooltip}><a href="https://www.linkedin.com/in/florian-gheorghe-33365ba3" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} size="1.5x" alt="Check My LinkedIn" /></a><p className={containerStyles.tooltipText}>Find Me On LinkedIn</p></li>
                                    <li className={containerStyles.tooltip}><a href="mailto:contact@florianghe.com"><FontAwesomeIcon icon={faEnvelope} size="1.5x" alt="Send Me An Email" /></a><p className={containerStyles.tooltipText}>Send Me An Email</p></li>
                                </ul>
                            </div>
                            <div className={containerStyles.cardScroll}>
                                <div className={containerStyles.container}>
                                    <div className={containerStyles.row}>
                                        <div className={containerStyles.scrollWrapper}>
                                            <a href="#services"><FontAwesomeIcon icon={faLongArrowAltDown} size="1.5x" alt="Down Arrow" /></a><span>SEE MY OFFER</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}