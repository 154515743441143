import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as containerStyles from "./portfolio.module.css"

export default function Portfolio() {
    return (
        <div className={containerStyles.portfolioSection}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.folioTitle}>
                        <h2 id="portfolio">04. MY WORKFOLIO</h2>
                    </div>
                </div>
                <div className={containerStyles.workfolio}>
                    <div className={containerStyles.folioContent}>
                        <div className={containerStyles.folioItem1}>
                            <div className={containerStyles.singleFolio}>
                                <div>
                                    <StaticImage src="../images/blockchain-javascript.jpg" alt="Blockchain Javascript Project" className={containerStyles.folioImage} />
                                </div>
                                <div className={containerStyles.folioDescription1}>
                                    <div className={containerStyles.folioBox1}>
                                        <div className={containerStyles.folioCategory1}>
                                            <ul>
                                                <li>Design</li>
                                                <li>Programming</li>
                                            </ul>
                                        </div>
                                        <div className={containerStyles.folioBtn1}>
                                            <a href="http://blockchainjs.florianghe.com/" target="_blank" rel="noreferrer">GO TO PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={containerStyles.folioItem2}>
                            <div className={containerStyles.singleFolio}>
                                <div>
                                    <StaticImage src="../images/nba-baschet-portfolio-770.jpg" alt="NBA Baschet GatsbyJS Website Project" className={containerStyles.folioImage} />
                                </div>
                                <div className={containerStyles.folioDescription2}>
                                    <div className={containerStyles.folioBox2}>
                                        <div className={containerStyles.folioCategory2}>
                                            <ul>
                                                <li>Design</li>
                                                <li>Web Development</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className={containerStyles.folioBtn2}>
                                        <a href="http://nbabaschet.florianghe.com/" target="_blank" rel="noreferrer">GO TO PROJECT</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={containerStyles.folioItem3}>
                            <div className={containerStyles.singleFolio}>
                                <div>
                                    <StaticImage src="../images/gretzkyjs-big-portfolio.jpg" alt="GretzkyJS GatsbyJS Project" className={containerStyles.folioImage} />
                                </div>
                                <div className={containerStyles.folioDescription3}>
                                    <div className={containerStyles.folioBox3}>
                                        <div className={containerStyles.folioCategory3}>
                                            <ul>
                                                <li>Design</li>
                                                <li>Web Development</li>
                                            </ul>
                                        </div>
                                        <div className={containerStyles.folioBtn3}>
                                            <a href="http://gretzkyjs.florianghe.com/" target="_blank" rel="noreferrer">GO TO PROJECT</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}