import React from "react"
import { Link } from "gatsby"

import * as containerStyles from "./full.module.css"

export default function Full() {

    return (
        <div className={containerStyles.overlay}>
            <div className={containerStyles.container}>
                <nav className={containerStyles.menuWrapper}>
                    <ul>
                        <li><Link to="#about" className={containerStyles.linkItem} >About Me</Link></li>
                        <li><Link to="#skills" className={containerStyles.linkItem} >My Skills</Link></li>
                        <li><Link to="#services" className={containerStyles.linkItem} >My Services</Link></li>
                        <li><Link to="#portfolio" className={containerStyles.linkItem} >My Portfolio</Link></li>
                        <li><Link to="#contact" className={containerStyles.linkItem} >Contact Me</Link></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}