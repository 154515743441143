import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import * as containerStyles from "./skills.module.css"

export default function Skills() {
    return (
        <div className={containerStyles.skillSection}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.skillContent}>
                        <div className={containerStyles.skillTitle}>
                            <h2 id="skills">02. MY SKILLS</h2>
                            <p>I have been making a living in the online content creation business (writing) for more than 10 years. Currently, I’m actively involved in learning web development & programming.</p>
                        </div>
                        <div className={containerStyles.skillsGraph}>
                            <div className={containerStyles.barItem}>
                                <p>Content Writing - 95%</p>
                                <div className={containerStyles.progress}>
                                    <div className={containerStyles.fill95}></div>
                                </div>
                            </div>
                            <div className={containerStyles.barItem}>
                                <p>HTML & CSS - 70%</p>
                                <div className={containerStyles.progress}>
                                    <div className={containerStyles.fill70}></div>
                                </div>
                            </div>
                            <div className={containerStyles.barItem}>
                                <p>JS & JS Libraries (React/Gatsby) - 40%</p>
                                <div className={containerStyles.progress}>
                                    <div className={containerStyles.fill40}></div>
                                </div>
                            </div>
                            <div className={containerStyles.barItem}>
                                <p>Programming - 20%</p>
                                <div className={containerStyles.progress}>
                                    <div className={containerStyles.fill20}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={containerStyles.skillImage}>
                        <div>
                            <div className={containerStyles.imageWrapper}>
                                <StaticImage src="..\images\skills-blackwhite.jpg" alt="Florian Gheorghe" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}