// extracted by mini-css-extract-plugin
export const contactSection = "contact-module--contactSection--2jLzP";
export const container = "contact-module--container--veJj0";
export const row = "contact-module--row--3Fc66";
export const contactInfo = "contact-module--contactInfo--3bfdX";
export const contactTitle = "contact-module--contactTitle--2_KMd";
export const contactDesc = "contact-module--contactDesc--37Rqk";
export const singleInfo = "contact-module--singleInfo--gqJxa";
export const infoDetails = "contact-module--infoDetails--3bboq";
export const awesomeIcons = "contact-module--awesomeIcons--3IpJC";
export const contactForm = "contact-module--contactForm--3rJ2b";
export const formWrapper = "contact-module--formWrapper--2J1KZ";
export const form = "contact-module--form--3w6OO";
export const singleInput = "contact-module--singleInput--21RyU";
export const contactMessage = "contact-module--contactMessage--3cMFE";
export const formBtn = "contact-module--formBtn--W-rn9";