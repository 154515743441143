// extracted by mini-css-extract-plugin
export const mainCard = "main-card-module--mainCard--3iBAi";
export const container = "main-card-module--container--HBg6V";
export const row = "main-card-module--row--2TAmW";
export const overlay = "main-card-module--overlay--2xl06";
export const whiteBox = "main-card-module--whiteBox--20oeS";
export const rightBox = "main-card-module--rightBox--3Ax0K";
export const mainImage = "main-card-module--mainImage--2HSeH";
export const leftBox = "main-card-module--leftBox--1XNqk";
export const cardTop = "main-card-module--cardTop--3Txgl";
export const cardInfo = "main-card-module--cardInfo--C4trv";
export const cardSocial = "main-card-module--cardSocial--3uZyc";
export const tooltipText = "main-card-module--tooltipText--34UhC";
export const tooltip = "main-card-module--tooltip--ZH3d-";
export const scrollWrapper = "main-card-module--scrollWrapper--2tCK_";
export const cardScroll = "main-card-module--cardScroll--1d4E4";
export const mobileBlock = "main-card-module--mobileBlock--2d1mP";