import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons"

import * as containerStyles from "./footer.module.css"

export default function Footer() {
    return (
        <div className={containerStyles.footerSection}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.footerInfo}>
                        <div className={containerStyles.infoWrapper}>
                            <a href="https://florianghe.com/"><StaticImage src="../images/florianghe.png" alt="florianghe.com logo" /></a>
                            <span>© 2021 florianghe.com. Created with Gatsby JS. Based on <a href="https://themeforest.net/item/caracraft-cv-resume-portfolio/19850518" target="_blank" rel="noreferrer">Caracraft Site Template</a>. All rights reserved!</span>
                        </div>
                    </div>
                    <div className={containerStyles.toTop}>
                        <div className={containerStyles.toTopWrapper}>
                            <div>
                                <a href="#header"><FontAwesomeIcon icon={faLongArrowAltUp} alt="Arrow Up" className={containerStyles.arrowIcon} /></a>
                            </div>
                            <div>
                                <span>back to top</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}