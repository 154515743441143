import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Navigation from "../components/navigation"
import Header from "../components/header"
import MainCard from "../components/main-card"
import About from "../components/about"
import Skills from "../components/skills"
import Overlay from "../components/overlay"
import Services from "../components/services"
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"
import Footer from "../components/footer"

import SEO from "../components/seo"

export default function Home() {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
  <div>
    <Helmet>
      <html lang="en" />
    </Helmet>
    <SEO title="Welcome To My Digital Playground" description={data.site.siteMetadata.description} />
    <Header />
    <Navigation />
    <MainCard />
    <About />
    <Skills />
    <Overlay />
    <Services />
    <Portfolio />
    <Contact />
    <Footer />
  </div>
  )
}
