// extracted by mini-css-extract-plugin
export const portfolioSection = "portfolio-module--portfolioSection--GRHXA";
export const container = "portfolio-module--container--3H709";
export const row = "portfolio-module--row--15vQI";
export const folioTitle = "portfolio-module--folioTitle--2cq1c";
export const workfolio = "portfolio-module--workfolio--3551G";
export const folioContent = "portfolio-module--folioContent--3BNpr";
export const folioItem1 = "portfolio-module--folioItem1--2lyxq";
export const singleFolio = "portfolio-module--singleFolio--3MB8f";
export const folioImage = "portfolio-module--folioImage--3FKks";
export const folioDescription1 = "portfolio-module--folioDescription1--1Vsln";
export const folioBox1 = "portfolio-module--folioBox1--3BNg0";
export const folioCategory1 = "portfolio-module--folioCategory1--eEzZ2";
export const folioBtn1 = "portfolio-module--folioBtn1--2eeFx";
export const folioItem2 = "portfolio-module--folioItem2--26IqF";
export const folioDescription2 = "portfolio-module--folioDescription2--NKwcN";
export const folioBox2 = "portfolio-module--folioBox2--1wKR7";
export const folioCategory2 = "portfolio-module--folioCategory2--1bDW9";
export const folioBtn2 = "portfolio-module--folioBtn2--3mL4S";
export const folioItem3 = "portfolio-module--folioItem3--3trNo";
export const folioDescription3 = "portfolio-module--folioDescription3--1yjIC";
export const folioBox3 = "portfolio-module--folioBox3--19b25";
export const folioCategory3 = "portfolio-module--folioCategory3--1XD-9";
export const folioBtn3 = "portfolio-module--folioBtn3--11zk4";