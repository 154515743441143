import React from "react"
import { useState, useCallback } from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import * as containerStyles from "./navigation.module.css"
import Burger from "../components/menu/burger"
import Full from "../components/menu/full"

export default function Navigation(initialValue = false) {
    const [isNotActive, menuIsActive] = useState(initialValue);
    const useMenu = useCallback(() => menuIsActive((isNotActive) => !isNotActive), []);
    
    const Toggle = styled.div`
        span {
            background-color: ${({ isNotActive }) => isNotActive ? '#000' : 'transparent' };   
        }

        span::before {
            top: ${({ isNotActive }) => isNotActive ? '10px' : '0' };
            width: ${({ isNotActive }) => isNotActive ? '20px' : '30px' };
            transform: ${({ isNotActive }) => isNotActive ? 'translate3d(0, 0, 0) rotate(0)' : 'translate3d(0, 0px, 0) rotate(45deg)' };
        }

        span::after {
            transform: ${({ isNotActive }) => isNotActive ? 'translate3d(0, 0, 0) rotate(0)' : 'translate3d(0, 0px, 0) rotate(-45deg)' };
            width: ${({ isNotActive }) => isNotActive ? '10px' : '30px' };
            top: ${({ isNotActive }) => isNotActive ? '20px' : '0' };
        }
        
    `

    const Overlay = styled.div`
        display: ${({ isNotActive }) => isNotActive ? 'none' : 'visible' };
        li:active {
            display: ${({ isNotActive }) => isNotActive ? 'none' : 'visible' };
        }
    `

    return (
        <>
        <div className={containerStyles.container}>
            <div class={containerStyles.logo}>
                <StaticImage src="../images/florianghe.png" alt="florianghe.com logo" />
            </div>
            <Toggle className={containerStyles.menu} isNotActive ={isNotActive} onClick={useMenu} >
                <Burger>{ isNotActive ? 'notActive' : 'Active' }</Burger>
            </Toggle>
        </div>
        <Overlay isNotActive={isNotActive} onClick={useMenu} >
            <Full>{ isNotActive ? 'notActive' : 'Active' }</Full>
        </Overlay>
        </>
    )
}