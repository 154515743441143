// extracted by mini-css-extract-plugin
export const aboutSection = "about-module--aboutSection--3Cku6";
export const container = "about-module--container--1qmQO";
export const row = "about-module--row--L2Tp-";
export const aboutInfo = "about-module--aboutInfo--1jyIz";
export const aboutTitle = "about-module--aboutTitle--3Iz7s";
export const aboutText = "about-module--aboutText--oB32R";
export const mailIcon = "about-module--mailIcon--3LLy1";
export const signature = "about-module--signature--3ZiUv";
export const downloadCv = "about-module--downloadCv--VzDxk";
export const aboutImage = "about-module--aboutImage--MOutC";
export const imageWrapper = "about-module--imageWrapper--21DQ0";