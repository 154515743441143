import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

import * as containerStyles from "./about.module.css"

export default function About() {
    return (
        <div className={containerStyles.aboutSection}>
            <div className={containerStyles.container}>
                <div className={containerStyles.row}>
                    <div className={containerStyles.aboutInfo}>
                        <div className={containerStyles.aboutTitle}>
                            <h2 id="about">01. ABOUT ME</h2>
                        </div>
                        <div className={containerStyles.aboutText}>
                            <p>I was born in a system on the precipice of change. I took a peek and I was forever hypnotized. I ventured forth & soon enough, I found myself knee-deep in an undrainable swamp of prejudices and lies, gazing at the abyss of my own making. I stumbled, yet I rose again & again, feeling the warm starlight tightly pressed upon my cheek. The world around me shifted with every step I made, seeking for that perfect balance that could never be undone. The road was rocky, yet here am I, still willing to min-max, still willing to play that game of life... Welcome to my playground!</p>
                            <a href="#contact">Let's Talk<FontAwesomeIcon icon={faEnvelope} className={containerStyles.mailIcon} /></a>
                        </div>
                        <div className={containerStyles.signature}>
                            <StaticImage src="../images/florianghe.png" alt="Signature" />
                        </div>
                        <div className={containerStyles.downloadCv}>
                            <a href="https://florianghe.com/">DOWNLOAD CV</a>
                        </div>
                    </div>
                    <div className={containerStyles.aboutImage}>
                        <div>
                            <div className={containerStyles.imageWrapper}>
                                <StaticImage src="..\images\about-me-blackwhite.jpg" alt="Florian Gheorghe" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}